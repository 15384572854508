import * as React from "react";
import { Link, graphql } from "gatsby";
import moment from "moment";
import Layout from "../templates/layout";
import HeroComponent from "../templates/components/hero.component";
import { GatsbySeo } from "gatsby-plugin-next-seo";

// markup
const IssuesPage = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title="Issues"
        openGraph={{
          title: "Issues",
          description:
            "3cents Magazine puts authors and their writing in conversation with one other",
          images: [
            {
              url: `https://www.3centsmagazine.com/images/logo.jpeg`,
              width: 430,
              height: 160,
              alt: "3Cents Magazine Logo",
            },
          ],
        }}
      />

      {data.allStrapiIssue.edges.map(({ node }, i) => {
        const publishDate = moment(node.publishDate);

        return (
          <Link
            to={`/issues/${node.slug}`}
            key={node.id}
            className="mb-8 block"
          >
            <HeroComponent
              image={node.image}
              title={"Issue " + node.issueNumber + ": " + node.title}
              subtitle={publishDate.format("LL")}
            />
          </Link>
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query IssuesPage {
    allStrapiIssue(sort: { fields: issueNumber, order: DESC }) {
      edges {
        node {
          title
          issueNumber
          id
          publishDate
          slug
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
              }
            }
          }
        }
      }
    }
  }
`;

export default IssuesPage;
