import React from "react";
import { graphql } from "gatsby";
import moment from "moment";
import Layout from "../layout";
import HeroComponent from "../components/hero.component";
import Pager from "../components/pager/pager.component";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { getImage } from "gatsby-plugin-image";

function BlogPostTemplate({ data, pageContext }) {
  const next = pageContext.next
    ? {
        url: `/blog/${pageContext.next.slug}`,
        title: pageContext.next.title,
      }
    : null;

  const prev = pageContext.prev
    ? {
        url: `/blog/${pageContext.prev.slug}`,
        title: pageContext.prev.title,
      }
    : null;

  const publishedAt = moment(data.strapiBlog.published_at);

  const image = getImage(data.strapiBlog.image.localFile);

  return (
    <Layout>
      <article>
        <GatsbySeo
          title={data.strapiBlog.title}
          description={data.strapiBlog.description}
          openGraph={{
            title: data.strapiBlog.title,
            description: data.strapiBlog.description,
            images: [
              {
                url: `https://www.3centsmagazine.com${image.images.fallback.src}`,
                width: image.width,
                height: image.height,
                alt: data.strapiBlog.title,
              },
            ],
          }}
        />

        <HeroComponent
          image={data.strapiBlog.image}
          title={data.strapiBlog.title}
          subtitle={"On " + publishedAt.format("LL")}
        />

        <section
          className="max-w-screen-md mx-auto article mt-8"
          dangerouslySetInnerHTML={{ __html: data.strapiBlog.content }}
        ></section>

        <Pager next={next} previous={prev} />
      </article>
    </Layout>
  );
}
export default BlogPostTemplate;

export const query = graphql`
  query BlogPostTemplate($slug: String!) {
    strapiBlog(slug: { eq: $slug }) {
      id
      title
      content
      published_at
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(height: 600)
          }
        }
      }
    }
  }
`;
