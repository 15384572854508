import React from "react";
import { Link } from "gatsby"

const PagerLink = ({ url, title, direction = 'next' }) => {
  const justify = direction === 'next' ? 'sm:justify-end' : 'sm:justify-start';
  const cssClass = `py-8 sm:px-6 flex w-full ${justify}`;

  return (
  <Link to={url} className={cssClass}>
    { direction === 'previous' && (
      <div className='mr-4 flex'>
        <svg viewBox="0 0 9 16" className='stroke-current h-10'>
          <polyline fill="none" strokeMiterlimit="10" points="7.3,14.7 2.5,8 7.3,1.2"></polyline>
        </svg>
      </div>
    )}

    <div>
      <div>{ direction === 'next' ? 'Next' : 'Previous' }</div>
      <div className="font-bold text-lg link">{ title }</div>
    </div>
    
    { direction === 'next' && (
      <div className='mr-4 flex'>
        <svg viewBox="0 0 9 16" className='stroke-current h-10 ml-4'>
          <polyline fill="none" strokeMiterlimit="10" points="1.6,1.2 6.5,7.9 1.6,14.7"></polyline>
        </svg>
      </div>
    )}
    </Link>
      
  );
};

export default PagerLink;