import * as React from "react";
import { graphql } from "gatsby";
import IssueComponent from "../templates/components/issue.component";
import Layout from "../templates/layout";
import { StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

// markup
const IndexPage = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title="Home"
        description="Published together reads better"
        openGraph={{
          title: "Home",
          description: "Published together reads better",
          images: [
            {
              url: `https://www.3centsmagazine.com/images/logo.jpeg`,
              width: 430,
              height: 160,
              alt: "3Cents Magazine Logo",
            },
          ],
        }}
      />

      {data.allStrapiIssue.edges.map(({ node }, i) => {
        return (
          <div key={node.id}>
            {i !== 0 && (
              <div className="flex justify-center ites-center py-24">
                <StaticImage
                  src="../images/divider-2.png"
                  width={320}
                  alt="Divider"
                />
              </div>
            )}

            <IssueComponent issue={node} index={i + 1} />
          </div>
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  query IndexPage {
    allStrapiIssue(sort: { fields: issueNumber, order: DESC }) {
      edges {
        node {
          title
          issueNumber
          id
          content
          publishDate
          slug
          image {
            localFile {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
              }
            }
          }
          articles {
            title
            slug
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
