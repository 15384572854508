import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import AuthorComponent from "../components/author.component";
import HeroComponent from "../components/hero.component";
import Pager from "../components/pager/pager.component";
import { getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

function ArticleTemplate({ data, pageContext }) {
  const next = pageContext.next
    ? {
        url: `/issues/${pageContext.issueSlug}/${pageContext.next.slug}`,
        title: pageContext.next.title,
      }
    : null;

  const prev = pageContext.prev
    ? {
        url: `/issues/${pageContext.issueSlug}/${pageContext.prev.slug}`,
        title: pageContext.prev.title,
      }
    : null;

  const image = getImage(data.strapiArticle.image.localFile);
  const preserveWhitespace = data.strapiArticle.preserveWhitespace || false;

  return (
    <Layout>
      <GatsbySeo
        title={data.strapiArticle.title}
        description={data.strapiArticle.description}
        openGraph={{
          title: data.strapiArticle.title,
          description: data.strapiArticle.description,
          images: [
            {
              url: `https://www.3centsmagazine.com${image.images.fallback.src}`,
              width: image.width,
              height: image.height,
              alt: data.strapiArticle.title,
            },
          ],
        }}
      />

      <article>
        <HeroComponent
          image={data.strapiArticle.image}
          title={data.strapiArticle.title}
          subtitle={"by " + data.strapiArticle.author.name}
        />

        <section
          style={{ boxShadow: "0 0 40px rgb(0 0 0 / 10%)" }}
          className={`max-w-screen-md mx-auto article mt-4 border-16 md:border-32 border-transparent ${
            preserveWhitespace ? "whitespace-nowrap overflow-x-auto" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: data.strapiArticle.content }}
        ></section>

        <aside className="max-w-screen-md mx-auto py-8 article border-t-1">
          <AuthorComponent
            name={data.strapiArticle.author.name}
            content={data.strapiArticle.author.content}
            twitter={data.strapiArticle.author.twitter}
            instagram={data.strapiArticle.author.instagram}
          />
        </aside>

        <hr />

        <Pager next={next} previous={prev} />
      </article>
    </Layout>
  );
}
export default ArticleTemplate;

export const query = graphql`
  query ArticleTemplate($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      title
      content
      preserveWhitespace
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(height: 600)
          }
        }
      }
      author {
        id
        name
        twitter
        instagram
        content
      }
    }
  }
`;
