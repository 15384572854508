module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Published together reads better","titleTemplate":"%s | 3cents Magazine","openGraph":{"type":"website","locale":"en_IE","url":"https://www.3centsmagazine.com/","site_name":"3cents Magazine"},"twitter":{"handle":"@3centsMagazine","site":"@3centsMagazine","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TH4NM8C","includeInDevelopment":false,"routeChangeEventName":"route-change","enableWebVitalsTracking":true,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
