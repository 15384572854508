import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const AuthorTemplate = ({ data }) => (
  <Layout>
    <GatsbySeo
      title={data.strapiAuthor.title}
      openGraph={{
        title: data.strapiAuthor.title,
        images: [
          {
            url: `https://www.3centsmagazine.com/images/logo.jpeg`,
            width: 430,
            height: 160,
            alt: "3Cents Magazine Logo",
          },
        ],
      }}
    />

    <h1>{data.strapiAuthor.name}</h1>
    <section
      dangerouslySetInnerHTML={{ __html: data.strapiAuthor.content }}
    ></section>
  </Layout>
);

export default AuthorTemplate;

export const query = graphql`
  query AuthorTemplate($id: String!) {
    strapiAuthor(id: { eq: $id }) {
      name
      content
    }
  }
`;
