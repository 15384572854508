import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../templates/layout";
import moment from "moment";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const BlogTemplate = ({ data }) => (
  <Layout>
    <GatsbySeo
      title="Blog"
      openGraph={{
        title: "Blog",
        description: "Published together reads better",
        images: [
          {
            url: `https://www.3centsmagazine.com/images/logo.jpeg`,
            width: 430,
            height: 160,
            alt: "3Cents Magazine Logo",
          },
        ],
      }}
    />

    <div className="max-w-screen-md mx-auto article mt-8">
      {data.allStrapiBlog.edges.map(({ node }) => {
        const publishedAt = moment(node.published_at);

        return (
          <article className="mb-12" key={node.id}>
            <Link to={`/blog/${node.slug}`} className="link inline-block mb-3">
              <h1>{node.title}</h1>
            </Link>
            <div className="text-sm font-sans mb-1">
              {publishedAt.format("LL")}
            </div>
            <section
              dangerouslySetInnerHTML={{ __html: node.shortContent }}
            ></section>
          </article>
        );
      })}
    </div>
  </Layout>
);

export default BlogTemplate;

export const query = graphql`
  query BlogTemplate {
    allStrapiBlog(sort: { order: DESC, fields: published_at }) {
      edges {
        node {
          title
          slug
          shortContent
          id
          published_at
        }
      }
    }
  }
`;
