import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

const ArticlesComponent = ({ articles, issue }) => {
  return (
    <div className={`grid grid-cols-1 md:grid-cols-3 w-full gap-1 auto-rows-max`}>
      {articles.map((article, i) => {
        const image = getImage(article.image.localFile.childImageSharp.gatsbyImageData);

        return (
            <Link key={i} to={`/issues/${issue.slug}/${article.slug}`} className='card'>
                <GatsbyImage image={image} alt={article.title} className='w-full h-full relative opacity-50' objectPosition='center center' />

                <p id="title" className="text-white text-4xl font-serif absolute z-10 p-8 text-shadow">
                  {article.title}
                </p>
            </Link>
        );
      })}
    </div>
  );
};

export default ArticlesComponent;
