import React from "react";
import { graphql } from "gatsby";
import IssueComponent from "../components/issue.component";
import Layout from "../layout";
import { getImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const IssueTemplate = ({ data }) => {
  const image = getImage(data.strapiIssue.image.localFile);

  return (
    <Layout>
      <GatsbySeo
        title={data.strapiIssue.title}
        description={data.strapiIssue.description}
        openGraph={{
          title: data.strapiIssue.title,
          description: data.strapiIssue.description,
          images: [
            {
              url: `https://www.3centsmagazine.com${image.images.fallback.src}`,
              width: image.width,
              height: image.height,
              alt: data.strapiIssue.title,
            },
          ],
        }}
      />
      <IssueComponent issue={data.strapiIssue} />
    </Layout>
  );
};

export default IssueTemplate;

export const query = graphql`
  query IssueTemplate($id: String!) {
    strapiIssue(id: { eq: $id }) {
      title
      description
      content
      issueNumber
      publishDate
      slug
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(height: 600, width: 600)
          }
        }
      }
      articles {
        title
        slug
        image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(height: 600, width: 600)
            }
          }
        }
      }
    }
  }
`;
