import React from "react";
import { Helmet } from "react-helmet";
import NavComponent from "./components/nav.component";
import moment from "moment";

const Layout = ({ children }) => {
  const year = moment().format("y");

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Raleway:wght@400&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <div className="layout">
        <NavComponent />

        <div className="p-4 sm:p-8 md:px-4">{children}</div>
      </div>

      <div className="p-8 text-center text-xs">
        &copy; {year} 3Cents Magazine. All rights reserved
      </div>
    </>
  );
};

export default Layout;
