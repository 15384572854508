import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../templates/layout";
import HeroComponent from "../templates/components/hero.component";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title={data.strapiAbout.title}
        description="3cents Magazine puts authors and their writing in conversation with one other"
        openGraph={{
          title: data.strapiAbout.title,
          description:
            "3cents Magazine puts authors and their writing in conversation with one other",
          images: [
            {
              url: `https://www.3centsmagazine.com/images/logo.jpeg`,
              width: 430,
              height: 160,
              alt: "3Cents Magazine Logo",
            },
          ],
        }}
      />

      <HeroComponent
        image={data.strapiAbout.image}
        title={data.strapiAbout.title}
      />

      <article className="max-w-screen-md mx-auto article mt-8">
        <section
          className="mb-8"
          dangerouslySetInnerHTML={{ __html: data.strapiAbout.content }}
        ></section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query AboutPage {
    strapiAbout {
      title
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(height: 600, width: 600)
          }
        }
      }
      content
    }
  }
`;

export default AboutPage;
