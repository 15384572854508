import React from "react";
import { Link } from "gatsby";
import { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image";


const BurgerComponent = () => {
  const [active, setActive] = useState(false);

  const handleClick = () => {
    setActive(!active);
  };

  return (
    <>
      <button className={`${ active ? 'burger-open' : 'burger-closed' } burger`} id='burger' onClick={ handleClick }>
        <span className='visually-hidden'>Open Menu</span>
        <span className='visually-hidden' hidden>Close Menu</span>
      </button>

      <div className={`${ active ? 'menu-open' : 'menu-closed' } menu`}>
        <nav className="block w-full">
          <Link className='menu-link' to="/">Home</Link>
          <Link className='menu-link' activeClassName='nav-link-active' to="/issues" partiallyActive={true}>Issues</Link>
          <Link className='menu-link' to="/blog">Blog</Link>
          {/* <Link className='menu-link' to="/authors">Authors</Link> */}
          <Link className='menu-link' to="/about">About Us</Link>
          <Link className='menu-link' to="/submit">Submit</Link>
          <a rel='noreferrer' className='menu-link' href='https://twitter.com/3centsMagazine' target='_blank'><StaticImage src="../../images/twitter.svg" width={32} alt='Twitter' /></a>
        </nav>
      </div>
    </>
  );
};

export default BurgerComponent;
