import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import BurgerComponent from "./burger.component";

const NavComponent = () => {
  return (
    <div className="sticky top-0 sm:static h-22 sm:h-32 md:h-48 z-50 w-full bg-white">
      <div className="py-2 px-4 sm:px-8 md:p-0 flex justify-start sm:justify-center justify-between items-center">
        <Link to="/" className="block w-48 sm:w-96 flex items-center">
          <StaticImage src="../../images/logo.jpeg" alt="Logo" />
        </Link>

        <div className="sm:hidden">
          <BurgerComponent />
        </div>
      </div>

      <nav className="hidden sm:flex w-full flex justify-center mt-4">
        <Link className="nav-link" activeClassName="nav-link-active" to="/">
          Home
        </Link>
        <Link
          className="nav-link"
          activeClassName="nav-link-active"
          to="/issues"
          partiallyActive={true}
        >
          Issues
        </Link>
        <Link
          className="nav-link"
          activeClassName="nav-link-active"
          to="/blog"
          partiallyActive={true}
        >
          Blog
        </Link>
        {/* <Link className='nav-link' activeClassName='nav-link-active' to="/authors">Authors</Link> */}
        <Link
          className="nav-link"
          activeClassName="nav-link-active"
          to="/about"
        >
          About Us
        </Link>
        <Link
          className="nav-link"
          activeClassName="nav-link-active"
          to="/submit"
        >
          Submit
        </Link>
        <a
          rel="noreferrer"
          className="nav-link"
          href="https://twitter.com/3centsMagazine"
          target="_blank"
        >
          <StaticImage
            src="../../images/twitter.svg"
            width={32}
            alt="Twitter"
          />
        </a>
      </nav>
    </div>
  );
};

export default NavComponent;
