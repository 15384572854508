import React from "react"
import { graphql } from "gatsby"
import AuthorComponent from "../templates/components/author.component"
import Layout from '../templates/layout';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

const AuthorsTemplate = ({ data }) => (
  <Layout>
    <GatsbySeo
      title='Authors'
      openGraph={{
        title: 'Authors'
      }}
    />

    <article className="max-w-screen-md mx-auto article mt-8">
      <h1 className='text-4xl pb-8 font-serif'>Authors</h1>
      {data.allStrapiAuthor.edges.map(({node}) => {
        return (
          <div className='mb-12' key={node.id}>
            <AuthorComponent
              name={node.name}
              content={node.content}
              twitter={node.twitter}
              instagram={node.instagram} />
          </div>
        )
      })}
    </article>
  </Layout>
  
)

export default AuthorsTemplate

export const query = graphql`
  query AuthorsTemplate {
    allStrapiAuthor {
      edges {
        node {
          id
          name
          content
          slug
          twitter
          instagram
        }
      }
    }
  }
`
