// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-authors-js": () => import("./../../../src/pages/authors.js" /* webpackChunkName: "component---src-pages-authors-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-js": () => import("./../../../src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */),
  "component---src-pages-submit-js": () => import("./../../../src/pages/submit.js" /* webpackChunkName: "component---src-pages-submit-js" */),
  "component---src-templates-pages-article-js": () => import("./../../../src/templates/pages/article.js" /* webpackChunkName: "component---src-templates-pages-article-js" */),
  "component---src-templates-pages-author-js": () => import("./../../../src/templates/pages/author.js" /* webpackChunkName: "component---src-templates-pages-author-js" */),
  "component---src-templates-pages-blog-post-js": () => import("./../../../src/templates/pages/blog-post.js" /* webpackChunkName: "component---src-templates-pages-blog-post-js" */),
  "component---src-templates-pages-issue-js": () => import("./../../../src/templates/pages/issue.js" /* webpackChunkName: "component---src-templates-pages-issue-js" */)
}

