import React from 'react';

const AuthorComponent = ({ name = 'The author', content = '', instagram = '', twitter = '', picture }) => {
  return (
    <div className='flex'>
      <div className='font-serif'>
        <h3 className='font-bold'>{ name }</h3>
        
        { content && (
          <section className='italic pb-4' dangerouslySetInnerHTML={{__html: content }}></section>
        )}
        
        { (twitter || instagram) && (
          <span>
            <span>You can find them on </span>
            { twitter && (
              <a href={'https://twitter.com/' + twitter } target="_blank" rel="noreferrer" className='link'>twitter</a>
            )}

            { twitter && instagram && (
              <span> and </span>
            )}

            { instagram && (
              <a href={'https://instagram.com/' + instagram } target="_blank" rel="noreferrer" className='link'>instagram</a>
            )}
            .
          </span>
        )}
      </div>

      {picture && (
      <figure className='w-full h-full relative'>
      </figure>
      )}
    </div>
  )
}

export default AuthorComponent;
