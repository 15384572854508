import React from 'react'
import HeroComponent from './hero.component';
import moment from 'moment';
import ArticlesComponent from './articles.component';
import { Link } from "gatsby"


const IssueComponent = ({ issue }) => {
  const publishDate = moment(issue.publishDate);
  
  return (
    <article key={issue.id}>
      <Link to={`/issues/${issue.slug}`}>
        <HeroComponent image={issue.image} title={'Issue ' + issue.issueNumber + ': ' + issue.title} subtitle={publishDate.format('LL')} />
      </Link>
      
      <section className='article max-w-screen-md mx-auto article pt-12 pb-12'>
        <section dangerouslySetInnerHTML={{__html: issue.content }}></section>
      </section>

      <div>
        <ArticlesComponent articles={issue.articles} issue={issue} />
      </div>
    </article>
  )
}


export default IssueComponent
