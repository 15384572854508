import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../templates/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";

const SubmitPage = ({ data }) => {
  return (
    <Layout>
      <GatsbySeo
        title="Submit"
        openGraph={{
          title: "Submit",
          description:
            "3cents Magazine puts authors and their writing in conversation with one other",
          images: [
            {
              url: `https://www.3centsmagazine.com/images/logo.jpeg`,
              width: 430,
              height: 160,
              alt: "3Cents Magazine Logo",
            },
          ],
        }}
      />

      <div className="max-w-screen-md mx-auto mt-8">
        <article className="article">
          <h1 className="mb-4">{data.strapiSubmit.title}</h1>

          <section
            className="mb-8"
            dangerouslySetInnerHTML={{ __html: data.strapiSubmit.content }}
          ></section>
        </article>

        <a
          href={data.strapiSubmit.submitLink}
          target="_blank"
          rel="noreferrer"
          className="button"
        >
          Submit Here
        </a>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SubmitPage {
    strapiSubmit {
      title
      submitLink
      content
    }
  }
`;

export default SubmitPage;
