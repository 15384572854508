import React from "react";
import PagerLink from './pager-link.component';

const Pager = ({ previous, next }) => {
  return (
    <div className='p-4 sm:p-0 flex flex-col-reverse items-center sm:flex-row sm:justify-between'>
      { previous && (
        <PagerLink url={previous.url} title={previous.title} direction='previous' />
      )}

      { (!previous || !next) && (
        <div className='w-full'></div>
      )}

      { next && (
        <PagerLink url={next.url} title={next.title} direction='next' />
      )}
    </div>
  );
};

export default Pager;