import React from 'react';
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const HeroComponent = ({ title = '', subtitle = '', image }) => {
  const heroImage = getImage(image.localFile.childImageSharp.gatsbyImageData);

  return (
    <div className='relative h-64 md:h-96 flex items-center justify-center bg-gray-800 rounded-md overflow-hidden'>

      <GatsbyImage image={heroImage} alt={title} className='w-full h-full relative opacity-50' />

      <div className="absolute z-10 py-8 px-16">
        <h1 className='text-white text-4xl md:text-7xl font-serif text-shadow'>
          {title}
        </h1>

        <h2 className='text-white text-2xl font-serif text-shadow'>
          {subtitle}
        </h2>
      </div>
    </div>
  )
}

export default HeroComponent;